<template>
  <el-tag :type="options.type"><slot /></el-tag>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
