import _ from 'lodash';
import Vuex from 'vuex';
import Vue from 'vue';

import router from '@/router';

Vue.use(Vuex);

const menuChildrenIteratee = p => o => {
  const current = _.assign({}, o, {
    breadcrumb: p ? [... p.breadcrumb, o.title] : [o.title],
  });
  const children = _(o.children).map(menuChildrenIteratee(current)).flatten().value()
  return [current, ...children]
}

const rejectChildren = (list, fn) => {
  return _(list).reject(fn).map(o => {
    const children = rejectChildren(o.children, fn);
    if(!_.size(children)) return _.omit(o, ['children']);
    return _.assign({}, o, { children })
  }).value()
}

const store = new Vuex.Store({
  state: {
    count: 0,
    accountInfo: null,
    task: null,

    menuList: [],
    menuItems: [],
  },

  mutations: {
    increment (state) {
      state.count++
    },

    setAccountInfo(state, accountInfo) {
      state.accountInfo = accountInfo;
      if(accountInfo) {
        state.menuList = _(accountInfo.menu).map(menuChildrenIteratee()).flatten().value();

        const listMenuItems = list => _(list).map(o => o.type === 2 ? _.omit(o, ['children']) : (_.size(o.children) ? _.assign({}, o, { children: listMenuItems(o.children )}) : o )).value();
        
        state.menuItems = listMenuItems(accountInfo.menu)

        const listComponent = list => _(list).map(o => [].concat(_.size(o.children) ? listComponent(o.children) : []).concat((o.type === 2 && o.component && o.path) ? [o] : [])).compact().flatten().value();

        router.addRoute({
          name: 'main', path: '/', component: () => import('@/views/Layout'),
          children: _.map(listComponent(accountInfo.menu), o => ({
            path: o.path,
            component: () => import(`@/views/${o.component}`),
          })).concat([
            { path: '/', component: () => import('@/views/page/Page_Index')},
            { path: '/*', component: () => import('@/views/page/Page_404')},
          ])
        });

        console.log(router.getRoutes())
      } else {
        router.reset();
      }
    },
    setTask(state, task) {
      state.task = task;
    }
  }
})

export default store;