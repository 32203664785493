import Vue from 'vue';
import { Notification } from 'element-ui';
import moment from 'moment';

import cellDblclick from './cellDblclick';
import uploadFile from './uploadFile';

Vue.mixin(cellDblclick)
Vue.mixin(uploadFile)

Vue.mixin({
  methods: {
    // 单元格双击
    $success: message => Notification({ type: 'success', message }),
    $error: message => Notification({ type: 'error', message }),
  }
})




Vue.filter('formatDate', value => {
  const m = moment(value);
  return m.isValid() ? m.format('YYYY-MM-DD') : ''
})