<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="750px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="项目名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入" />
      </el-form-item>

      <el-form-item label="项目编号">
        <el-input v-model="form.code" placeholder="请输入" />
      </el-form-item>

      <el-form-item label="项目分类" prop="xmfl">
        <el-cascader v-model="form.xmfl" placeholder="请选择" style="width: 100%" :props="{ label: 'name', value: 'value' }" :options="xmfl_cascader" />
      </el-form-item>

      <el-form-item label="申请资金池" prop="cash_pool_id">
        <el-select v-model="form.zjnf" placeholder="资金年份" style="width: 100px">
          <el-option v-for="o in zjnf_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
        <el-select v-model="form.cash_pool_id" placeholder="请选择" style="width: calc(100% - 120px); margin-left: 10px;">
          <el-option v-for="o in cash_pool_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="申请金额" prop="apply_fee">
        <el-input-number v-model="form.apply_fee" controls-position="right" :disabled="!max_apply_fee" />
        <span style="margin-left: 10px;">万元</span>
        <span style="margin-left: 20px; color: red;" v-if="max_apply_fee">剩余：{{ max_apply_fee }}万元</span>
      </el-form-item>

      <el-form-item label="责任单位">
        <el-select v-model="form.dep_id" placeholder="请选择">
          <el-option v-for="o in dep_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="责任人">
        <el-select v-model="form.user_id" placeholder="请选择">
          <el-option v-for="o in user_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="实施主体">
        <el-input v-model="form.sszt" placeholder="请输入" />
      </el-form-item>

      <el-form-item label="实施时间">
        <el-date-picker v-model="form.sssj" type="daterange" range-separator="至" value-format="yyyy-MM-dd" />
      </el-form-item>

      <el-form-item label="自筹资金">
        <el-input-number v-model="form.zczj" placeholder="请输入" controls-position="right" />
        <span style="margin-left: 10px;">万元</span>
      </el-form-item>

      <el-form-item label="类型">
        <el-radio-group v-model="form.lx">
          <el-radio label="自筹">自筹</el-radio>
          <el-radio label="约束">约束</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
import BigNumber from 'bignumber.js';
import json_keys from '../my/json_keys';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: { lx: '自筹'  },

      confirmLoading: false,


      zjnf_options: [],
      cash_pool_options: [],
      cash_pool_list: [],

      dep_options: [],
      user_options: [],

      max_apply_fee: 0,

      xmfl_cascader: [],

      full_rules: {
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        xmfl: [
          { type: 'array', required: true, message: '请选择项目分类', trigger: 'blur' }
        ],
        cash_pool_id: [
          { required: true, message: '请输入申请资金池', trigger: 'blur'}
        ],
        apply_fee: [
          { required: true, message: '请输入申请金额', trigger: 'blur'}
        ]
      },

      json_keys
    }
  },

  computed: {
    rules() {
      return this.full_rules
    },
  },
  watch: {
    'form.zjnf': function(e) {
      this.$api.execute('ycjh_cms.cash_pool.list', { status: 'enabled', zjnf: e }).then(res => {
        
        this.cash_pool_options = _.map(res.list, o => ({ label: o.name, value: o.id }));
        this.cash_pool_list = res.list;

        const cash_pool = _.find(this.cash_pool_list, { id: this.form.cash_pool_id });
        const cash_pool_id = cash_pool ? cash_pool.id : null;
        this.max_apply_fee = cash_pool ? BigNumber(cash_pool.fee).minus(cash_pool.used_fee).toNumber() : 0;
        this.form = _.assign({}, this.form, { cash_pool_id })
        
      })
    },

    'form.cash_pool_id': function(cash_pool_id) {
      const cash_pool = _.find(this.cash_pool_list, { id: cash_pool_id });
      this.max_apply_fee = cash_pool ? BigNumber(cash_pool.fee).minus(cash_pool.used_fee).toNumber() : 0;
    },
  },

  methods: {
    async show(form, options = {}) {
      this.form = _.assign({}, this.defaultForm, form, {
        xmfl: form.xmfl ? form.xmfl.split('/') : [],
      }, form.json_data ? _.pick(JSON.parse(form.json_data), this.json_keys) : {});
      this.options = options;

      const [{ list: sys_dict }, { list: dep_list }, { list: user_list}] = await this.$api.executeArray(
        ['sys_dict.list', { code: ['zjrknf', 'zjfl'] }],
        ['sys_dep.list', {}],
        ['sys_user.list', {}]
      );

      this.zjnf_options = _.map(_.get(_.find(sys_dict, { code: 'zjrknf'}), 'children'), o => ({ label: o.name, value: o.code || o.name }));
      this.zjfl_options = _.map(_.get(_.find(sys_dict, { code: 'zjfl'}), 'children'), o => ({ label: o.name, value: o.code || o.name }));
      this.xmfl_cascader = _.get(_.find(sys_dict, { code: 'zjfl'}), 'children')

      this.dep_options = _.map(dep_list, o => ({ label: o.name, value: o.id }))
      this.user_options = _.map(user_list, o => ({ label: o.name, value: o.id }))

      this.confirmLoading = false;
      this.visible = true;
    },

    async confirmForm() {
      try {
        this.confirmLoading = true;
        await this.$refs.form.validate()

        const form = _.assign({}, _.omit(this.form, this.json_keys), {
          xmfl: this.form.xmfl.join('/'),
          sskssj: _.get(this.form.sssj, '[0]') || null,
          ssjssj: _.get(this.form.sssj, '[1]') || null,
          json_data: JSON.stringify(_.pick(this.form, this.json_keys))
        })
        if(this.form.id) {
          await this.$api.execute('ycjh_cms.project.update', this.form.id, form);
        } else {
          await this.$api.execute('ycjh_cms.project.create', form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        e.message && this.$error(e.message)
      } finally {
        this.confirmLoading = false;
      }
    },
  }
}
</script>