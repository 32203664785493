<template>
  <div style="padding: 10px;height: 70px;">
    <div style="margin-bottom: 10px">在建项目总数</div>
    <count-to :start-val="0" :end-val="value" :duration="3600" style="font-size: 30px;font-weight: bold;" /> 个
  </div>
</template>

<script>
import countTo from 'vue-count-to';

export default {
  label: '统计/项目/在建总数',
  components: {
    countTo
  },
  data() {
    return {
      value: 100,
    }
  },

  created() {
    this.init();
  }, 
  methods: {
    init() {
      this.$api.executeArray(
        ['ycjh_cms.project.list', { query_count: true, status: 3 }]
      ).then(([value]) => {
        this.value = value;
      })
    },
  }
}
</script>