<template>
  <div>

    <span v-for="o in columns" :key="o.prop" style="display:inline-block; margin-right: 10px; margin-bottom: 5px;">
      <el-date-picker v-if="o.valueType === 'datetime'" :value="value[o.prop]" @input="input($event, o.prop)" v-model="search[o.prop]" type="datetimerange" :start-placeholder="`${o.rename_label || o.label}起`" :end-placeholder="`${o.rename_label || o.label}止`" value-format="yyyy-MM-dd HH:mm:ss" />
      <el-date-picker v-else-if="o.valueType === 'date'" :value="value[o.prop]" @input="input($event, o.prop)" type="daterange" :start-placeholder="`${o.rename_label || o.label}起`" :end-placeholder="`${o.rename_label || o.label}止`" value-format="yyyy-MM-dd" />
      <el-select v-else-if="o.valueMap" :value="value[o.prop]" @input="input($event, o.prop)" :placeholder="o.rename_label || o.label" clearable>
        <el-option v-for="(label, value) in o.valueMap" :key="value" :value="value" :label="label" />
      </el-select>
      <el-select v-else-if="o.dict" :value="value[o.prop]" @input="input($event, o.prop)" :placeholder="o.rename_label || o.label" clearable>
        <el-option v-for="p in dict[o.dict]?.children" :key="p.value || p.name" :value="p.value || p.name" :label="p.name" />
      </el-select>
      <el-input v-else :value="value[o.prop]" @input="input($event, o.prop)" :placeholder="o.rename_label || o.label" style="width: 200px;" clearable :readonly="o.readonly" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    dict: Object,
    value: Object
  },

  methods: {
    input(value, prop) {
      this.$emit('input', _.assign({}, this.value, { [prop]: value }))
      console.log('input', { value, prop })
    }
  }
}
</script>
