<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="400px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="60px">

      <el-form-item label="名称">
        <el-input v-model="form.name" />
      </el-form-item>

      <el-form-item label="编码">
        <el-input v-model="form.code" />
      </el-form-item>

      <el-form-item label="排序">
        <el-input-number v-model="form.sort" controls-position="right" :min="0"></el-input-number>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});

      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('sys_dep.update', this.form.id, this.form);
        } else {
          await this.$api.execute('sys_dep.create', this.form);
        }
        this.options.success && this.options.success()
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>