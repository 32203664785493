<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="60px">

      <el-row>
        <el-col :span="12">
          <el-form-item label="名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="类型">
            <el-select v-model="form.type" placeholder="请选择" style="width: 100%">
              <el-option v-for="o in type_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item v-if="form.type === 2" label="组件">
        <el-autocomplete v-model="form.component" :fetch-suggestions="fetchSuggestion" style="width: 100%">
          <template slot-scope="{item}">
            {{ item.value }} <span style="color: lightgray; font-size: 10px;margin-left: 10px;">{{ item.label }}</span>
          </template>
        </el-autocomplete>
      </el-form-item>

      <el-form-item label="路径">
        <el-input v-model="form.path" />
      </el-form-item>

      <el-form-item label="排序">
        <el-input-number v-model="form.sort" controls-position="right" :min="0"></el-input-number>
      </el-form-item>

    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      type_options: [
        { label: '目录', value: 1 },
        { label: '页面', value: 2 },
      ],

      component_options: [
        { label: 'tab布局', value: 'page/Page_tab' },
        { label: '多组件', value: 'page/Page_multiple' },
        { label: '单组件', value: 'page/Page_single' },
      ]
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});

      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('sys_menu.update', this.form.id, this.form);
        } else {
          await this.$api.execute('sys_menu.create', this.form);
        }
        this.options.success && this.options.success()
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },

    fetchSuggestion(qs, cb) {
      cb(qs ? _.filter(this.component_options, o => o.value.indexOf(qs) !== -1) : this.component_options)
    }
  }
}
</script>