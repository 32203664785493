<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button :loading="confirmLoading" type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="资金池名称" :rules="[{ required: true, message: '请输入名称'}]">
        <el-input v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item label="资金池代码">
        <el-input v-model="form.code"></el-input>
      </el-form-item>

      <el-form-item label="资金年份" :rules="[{ required: true, message: '请输入资金年份'}]">
        <el-select v-model="form.zjnf" placeholder="请选择" style="width: 100%">
          <el-option v-for="o in zjnf_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="资金来源" :rules="[{ required: true, message: '请输入资金来源'}]">
        <el-select v-model="form.zjly" placeholder="请选择" style="width: 100%">
          <el-option v-for="o in zjly_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: { },
      defaultForm: { fee: 0 },

      zjnf_options: [],
      zjly_options: [],

      confirmLoading: false,
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, this.defaultForm, initForm, {});
      this.options = options;

      const sys_dict = (await this.$api.execute('sys_dict.list', { code: ['zjly', 'zjrknf'] })).list;
      
      this.zjnf_options = _.map(_.get(_.find(sys_dict, { code: 'zjrknf'}), 'children'), o => ({ label: o.name, value: o.code || o.name }));
      this.zjly_options = _.map(_.get(_.find(sys_dict, { code: 'zjly'}), 'children'), o => ({ label: o.name, value: o.code || o.name }));
      this.visible = true;
    },

    async confirmForm() {
      try {
        this.confirmLoading = true;
        if(this.form.id) {
          await this.$api.execute('ycjh_cms.cash_pool.update', this.form.id, this.form);
        } else {
          await this.$api.execute('ycjh_cms.cash_pool.create', this.form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$notify({
          type: 'error',
          message: e.message
        });
      } finally {
        this.confirmLoading = false;
      }
    },
  }
}
</script>