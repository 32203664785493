import _ from 'lodash';
import axios from 'axios';
import router from '@/router';

export default {
  async execute_throwAllError(...req) {
    try {
      const res = await axios.post('/api/gateway', [req]);
      return res.data[0]
    } catch(e) {
      const message = e.response.data;
      throw new Error(message)
    }
  },

  async execute(...req) {
    try {
      const res = await axios.post('/api/gateway', [req]);
      return res.data[0]
    } catch(e) {
      const message = e.response.data;
      if(message === '请登录') {
        router.replace(`/login?redirect=${router.history.current.path}`)
      } else {
        throw new Error(message)
      }
    }
  },

  async executeArray(...reqArray) {
    try {
      const res = await axios.post('/api/gateway', reqArray);
      return res.data;
    } catch(e) {
      const message = e.response.data;
      throw new Error(message)
    }
  },

}