<template>
  <div style="padding: 10px; ">
    <echart style="width: 100%; height: 200px;" :option="option" />
  </div>
</template>

<script>
export default {
  label: '统计/资金池/资金池各月使用情况',
  data() {
    return {
      option: null,
    }
  },

  created() {
    this.init();
  }, 
  methods: {
    async init() {

      this.option = {
        title: {
          text: '资金池各月使用情况',
          left: 'center',
        },
        xAxis: { data: ['2022-01', '2022-02', '2022-03', '2022-04', '2022-05', '2022-06'] },
        yAxis: {},
        grid: {},
        series: [
          {
            name: 'Traffic Sources',
            type: 'line',
            data: [335, 235, 335, 235, 335, 235],
          },
        ],
      }
    },
  }
}
</script>