<template>
  <v-chart style="width: 100%; height: 200px;" :option="option" autoresize />
</template>

<script setup>
import { defineComponent, defineProps } from 'vue';
defineProps({
  option: { type: Object },
})
</script>
<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import * as charts from 'echarts/charts';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';
import { defineComponent, defineProps } from 'vue';

const { 
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart
} = charts;

use([
  CanvasRenderer,
  
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default defineComponent({
  components: { VChart },
});
</script>

</script>