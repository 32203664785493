import Vue from 'vue'
import ComponentElTag from './ComponentElTag';
import ConfigForm from './ConfigForm';
import Echart from './echart';
import ConfigFieldsForm from './ConfigFieldsForm';

import ConfigTableForm from './ConfigTableForm';

import DownloadProgress from './DownloadProgress';
import SearchBar from './SearchBar';
import UploadSingle from './UploadSingle';

Vue.component('component-el-tag', ComponentElTag);
Vue.component('config-form', ConfigForm);
Vue.component('echart', Echart);
Vue.component('download-progress', DownloadProgress);

Vue.component('config-fields-form', ConfigFieldsForm);
Vue.component('config-table-form', ConfigTableForm);

Vue.component('search-bar', SearchBar);
Vue.component('upload-single', UploadSingle);