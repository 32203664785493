<template>
  <div>
    <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="750px">
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" :loading="confirmLoading" @click="confirmForm">确 定</el-button>
      </span>

      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item v-if="!fieldsMap.name.hide" :label="fieldsMap.name.rename_label || fieldsMap.name.label" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>

        <el-form-item v-if="!fieldsMap.code.hide" :label="fieldsMap.code.rename_label || fieldsMap.code.label" prop="code">
          <el-input v-model="form.code" placeholder="请输入" />
        </el-form-item>

        <el-form-item v-if="!fieldsMap.xmfl.hide" :label="fieldsMap.xmfl.rename_label || fieldsMap.xmfl.label" prop="xmfl">
          <el-cascader v-model="form.xmfl" placeholder="请选择" style="width: 100%" :props="{ label: 'name', value: 'value' }" :options="xmfl_cascader" />
        </el-form-item>

        <el-form-item v-if="!fieldsMap.cash_pool_id.hide" :label="fieldsMap.cash_pool_id.rename_label || fieldsMap.cash_pool_id.label" prop="cash_pool_id">
          <el-select v-model="form.zjnf" placeholder="资金年份" style="width: 100px">
            <el-option v-for="o in zjnf_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
          </el-select>
          <el-select v-model="form.cash_pool_id" placeholder="请选择" style="width: calc(100% - 120px); margin-left: 10px;">
            <el-option v-for="o in cash_pool_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="!fieldsMap.apply_fee.hide" :label="fieldsMap.apply_fee.rename_label || fieldsMap.apply_fee.label" prop="apply_fee">
          <el-input-number v-model="form.apply_fee" controls-position="right" :disabled="!max_apply_fee" />
          <span style="margin-left: 10px;">万元</span>
          <span style="margin-left: 20px; color: red;" v-if="max_apply_fee">剩余：{{ max_apply_fee }}万元</span>
        </el-form-item>

        <el-form-item v-if="!fieldsMap.sszt.hide" :label="fieldsMap.sszt.rename_label || fieldsMap.sszt.label" prop="sszt">
          <el-input v-model="form.sszt" placeholder="请输入" />
        </el-form-item>

        <el-form-item v-if="!fieldsMap.sssj.hide" :label="fieldsMap.sssj.rename_label || fieldsMap.sssj.label" prop="sssj">
          <el-date-picker v-model="form.sssj" type="daterange" range-separator="至" value-format="yyyy-MM-dd" />
        </el-form-item>

        <el-form-item v-if="!fieldsMap.zczj.hide" :label="fieldsMap.zczj.rename_label || fieldsMap.zczj.label" prop="zczj">
          <el-input-number v-model="form.zczj" placeholder="请输入" controls-position="right" />
          <span style="margin-left: 10px;">万元</span>
        </el-form-item>

        <el-form-item v-if="!fieldsMap.xmlx.hide" :label="fieldsMap.xmlx.rename_label || fieldsMap.xmlx.label" prop="xmlx">
          <el-radio-group v-model="form.xmlx">
            <el-radio :label="o.value" v-for="o in xmlx_options" :key="o.value">{{ o.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <div v-if="accountInfo.user.edit_page" style="position: absolute; right: 50px; top: 15px;">
        <el-button type="text" @click="showForm_config">配置</el-button>
      </div>

    </el-dialog>
    <config-fields-form ref="configForm" />
  </div>
</template>

<script>

import _ from 'lodash';
import BigNumber from 'bignumber.js';
import { mapState } from 'vuex';
import json_keys from './json_keys';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {  },

      confirmLoading: false,


      zjnf_options: [],
      cash_pool_options: [],
      cash_pool_list: [],

      max_apply_fee: 0,

      xmfl_cascader: [],
      xmlx_options: [],

      fields: [
        { label: '项目名称', prop: 'name', required: true },
        { label: '项目编号', prop: 'code', edit: ['required', 'hide'], hide: true },
        { label: '项目分类', prop: 'xmfl', required: true, type: 'array', },
        { label: '申请资金池', prop: 'cash_pool_id', required: true },
        { label: '申请金额', prop: 'apply_fee',  required: true },
        { label: '实施主体', prop: 'sszt', edit: ['required', 'hide'] },
        { label: '实施时间', prop: 'sssj', edit: ['required', 'hide'] },
        { label: '自筹资金', prop: 'zczj', edit: ['required', 'hide'] },
        { label: '项目类型', prop: 'xmlx', edit: ['required', 'hide'] },
      ],

      config: null,

      json_keys
    }
  },

  computed: {
    ...mapState(['accountInfo']),
    configFields() {
      return _.map(this.fields, o => _.assign({ }, o, _.pick(_.find(this.config?.fields, { prop: o.prop }), (o.edit || []).concat(['rename_label']))))
    },
    fieldsMap() {
      return _.mapKeys(this.configFields, 'prop')
    },
    rules() {
      return _(this.configFields).filter('required').reject('hide').mapKeys('prop').mapValues(o => {
        const rules = [];
        if(o.required) {
          rules.push(_.assign({ required: true, message: `请输入${o.label}`, trigger: 'blur' }, _.pick(o, ['type'])))
        }
        return rules;
      }).value()
    },

    configKey() {
      return `formConfig_ycjh_cms.project.my.Form_edit`;
    },
  },
  watch: {
    'form.zjnf': function(e) {
      this.$api.execute('ycjh_cms.cash_pool.list', { status: 'enabled', zjnf: e }).then(res => {
        
        this.cash_pool_options = _.map(res.list, o => ({ label: o.name, value: o.id }));
        this.cash_pool_list = res.list;

        const cash_pool = _.find(this.cash_pool_list, { id: this.form.cash_pool_id });
        const cash_pool_id = cash_pool ? cash_pool.id : null;
        this.max_apply_fee = cash_pool ? BigNumber(cash_pool.fee).minus(cash_pool.used_fee).toNumber() : 0;
        this.form = _.assign({}, this.form, { cash_pool_id })
        
      })
    },

    'form.cash_pool_id': function(cash_pool_id) {
      const cash_pool = _.find(this.cash_pool_list, { id: cash_pool_id });
      this.max_apply_fee = cash_pool ? BigNumber(cash_pool.fee).minus(cash_pool.used_fee).toNumber() : 0;
    },
  },

  methods: {

    getFieldConfig(prop) {
      return _.find(this.fields, { prop })
    },
    async show(form, options = {}) {
      this.form = _.assign({}, this.defaultForm, form, {
        xmfl: form.xmfl ? form.xmfl.split('/') : [],
      }, form.json_data ? _.pick(JSON.parse(form.json_data), this.json_keys) : {});
      this.options = options;

      const [{ list: [sys_params]}, { list: sys_dict }] = await this.$api.executeArray(
        ['sys_params.list', { code: this.configKey }],
        ['sys_dict.list', { code: ['zjrknf', 'zjfl', 'xmlx'] }]
      );

      this.config = JSON.parse((sys_params ? sys_params.value : null) || '{}');
      console.log(this.config)

      this.zjnf_options = _.map(_.get(_.find(sys_dict, { code: 'zjrknf'}), 'children'), o => ({ label: o.name, value: o.code || o.name }));
      this.zjfl_options = _.map(_.get(_.find(sys_dict, { code: 'zjfl'}), 'children'), o => ({ label: o.name, value: o.code || o.name }));
      this.xmfl_cascader = _.get(_.find(sys_dict, { code: 'zjfl'}), 'children');
      this.xmlx_options = _.map(_.get(_.find(sys_dict, { code: 'xmlx'}), 'children'), o => ({ label: o.name, value: o.code || o.name }));

      this.confirmLoading = false;
      this.visible = true;
      this.$refs.form?.resetFields()
    },

    async confirmForm() {
      try {
        this.confirmLoading = true;
        await this.$refs.form.validate()

        const form = _.assign({}, _.omit(this.form, this.json_keys), {
          xmfl: this.form.xmfl.join('/'),
          sskssj: _.get(this.form.sssj, '[0]') || null,
          ssjssj: _.get(this.form.sssj, '[1]') || null,
          json_data: JSON.stringify(_.pick(this.form, this.json_keys))
        })
        if(this.form.id) {
          await this.$api.execute('ycjh_cms.project.update', this.form.id, form);
        } else {
          await this.$api.execute('ycjh_cms.project.create', form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        e.message && this.$error(e.message)
      } finally {
        this.confirmLoading = false;
      }
    },

    saveConfig(config) {
      this.config = config;
      this.$api.execute('sys_params.replace', { code: this.configKey, value: JSON.stringify(this.config) })
    },

    showForm_config() {
      this.$refs.configForm.show(_.assign({}, this.config, {
        fields: this.configFields
      }), {
        title: '配置',
        success: config => {
          const fields = _(config.fields).map(o => {
            const field = _.find(this.fields, { prop: o.prop });
            const b = _.pickBy(o, (value, key) => field[key] !== value && undefined !== value)
            return _.size(b) ? _.assign({}, b, { prop: o.prop }) : null
          }).compact().value();

          this.saveConfig(_.assign({}, config, { fields }));
        }
      })
    }
  }
}
</script>