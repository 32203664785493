<template>
  <div v-if="visible" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255,255,255, 0.8); z-index: 2000;display: flex; align-items: center;justify-content: center;">
    <div style="text-align: center;">
      <el-progress type="circle" :percentage="percentage" :status="status" />
      <div style="margin-top: 10px;">
        <span>{{ message }}</span>
        <el-button v-if="!cancel && !status" type="text" @click="cancel = true">取消下载</el-button>
        <el-button v-if="status === 'exception'" type="text" @click="visible = false">关闭</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
export default {
  data() {
    return {
      visible: false,
      percentage: 0,
      message: '',
      status: null,
      cancel: false
    }
  },
  methods: {
    async download(fileList, zipFileName) {
      // TODO 报告批量下载

      this.percentage = 0
      this.message = '查询下载链接'
      this.status = null
      this.visible = true

      const zip = new JSZip()
      try {
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i]
          if (this.cancel) break
          this.percentage = parseInt(i / fileList.length * 100)
          this.message = `正在下载第 ${i} 个文件，共 ${fileList.length} 个文件。`
          try {
            const res = await axios.get(file.url, { responseType: 'blob' })
            zip.file(file.path, res.data, { binary: true })
          } catch (e) {
            console.log({ e })
            throw new Error(`下载第${i + 1}个文件 "${file.path}" 时发生错误: ${e.message}`)
          }
        }

        if (this.cancel) {
          this.message = '下载已取消'
          this.status = 'warning'
        } else {
          this.percentage = 100
          this.message = `下载成功，共 ${fileList.length} 个文件。`
          this.status = 'success'
          FileSaver.saveAs(await zip.generateAsync({ type: 'blob' }), `${zipFileName}_${moment().format('YYYYMMDD_HHmmss')}.zip`)
        }

        setTimeout(() => {
          this.visible = false
          this.percentage = 0
          this.message = ''
          this.status = null
        }, 1000)
      } catch (e) {
        this.message = e.message
        this.status = 'exception'
      }
    }
  }
}
</script>
