<template>
  <el-drawer :title="options.title" :visible.sync="visible" :wrapperClosable="false" width="800px">
    <span class="drawer-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="60px">
      <el-tree ref="menuTree" :default-checked-keys="menu_ids" node-key="id" :data="menu_tree" :props="{ label: 'name'}" show-checkbox></el-tree>
      
    </el-form>
  </el-drawer>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      menu_tree: [],

      menu_ids: []
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;

      const [{ list: menu_tree }, { list: role_menu_list }] = await this.$api.executeArray(
        ['sys_menu.list', {}],
        ['sys_role_menu.list', { role_id: this.form.id }]
      );
      const tree_map = (tree, mapFn) => _.map(tree, o => o.children ? _.assign({}, mapFn(o), { children: tree_map(o.children, mapFn)}) : mapFn(o))

      this.menu_ids = _.map(role_menu_list, 'menu_id');
      this.menu_tree = tree_map(menu_tree, o => _.assign({}, o, { name: _.includes(this.menu_ids, o.id) ? o.name : `${o.name}（未选择）`}));
      
      this.visible = true;
    },

    async confirmForm() {

      const menu_ids = this.$refs.menuTree.getCheckedKeys();
      try {
        if(this.form.id) {
          await this.$api.execute('sys_role.update_menu', this.form.id, menu_ids);
        }
        this.options.success && this.options.success()
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>