export default {
  methods: {
    // 单元格双击
    cellDblclick(row, column, cell, event) {
      if (!cell.innerText) return
      const input = document.createElement('input')
      document.body.appendChild(input)
      input.setAttribute('value', cell.innerText)
      input.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
        this.$notify({
          type: 'success',
          message: '复制成功！'
        })
      }
      document.body.removeChild(input)
    },
  }
}
