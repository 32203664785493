<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="60px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="名称">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="代码">
            <el-input v-model="form.code" />
          </el-form-item>
        </el-col>
      </el-row>
      
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});

      if(!_.size(this.form.items)) {
        this.form.items = [{}]
      }
      this.options = options;
      this.visible = true;
    },

    createRecord(index) {
      this.form = _.assign({}, this.form, {
        items: [
          ...this.form.items.slice(0, index + 1),
          {},
          ...this.form.items.slice(index + 1)
        ]
      })
    },

    removeRecord(index) {
      this.form = _.assign({}, this.form, {
        items: [
          ...this.form.items.slice(0, index),
          ...this.form.items.slice(index + 1)
        ]
      })
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('sys_role.update', this.form.id, this.form);
        } else {
          await this.$api.execute('sys_role.create', this.form);
        }
        this.options.success && this.options.success()
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>