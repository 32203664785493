<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px" top="50px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>
    <div style="max-height: calc(100vh - 250px); overflow-y: auto;">
      <el-form ref="form" :model="form" label-width="100px" >
        <el-table :data="form.columns" style="width: 100%">
          <el-table-column prop="label" label="字段" width="200">
            <template slot-scope="{row, $index}">
              <el-input v-model="form.columns[$index].rename_label" :placeholder="row.label" clearable />
            </template>
          </el-table-column>
          <el-table-column prop="hide" label="隐藏" width="50">
            <template slot-scope="{row, $index}">
              <el-checkbox v-model="form.columns[$index].hide" />
            </template>
          </el-table-column>
          <el-table-column v-if="edit_includes('search')" prop="search" label="查询" width="50">
            <template slot-scope="{row, $index}">
              <el-checkbox v-if="row.edit && row.edit.indexOf('search') !== -1" v-model="form.columns[$index].search" />
            </template>
          </el-table-column>

          <el-table-column v-if="edit_includes('sum')" prop="sum" label="总和" width="50">
            <template slot-scope="{row, $index}">
              <el-checkbox v-if="row.edit && row.edit.indexOf('sum') !== -1" v-model="form.columns[$index].sum" />
            </template>
          </el-table-column>

          <el-table-column prop="width" label="宽度" width="120">
            <template slot-scope="{row, $index}">
              <el-input-number v-model="form.columns[$index].width" size="mini" controls-position="right" style="width:100%" />
            </template>
          </el-table-column>

          <el-table-column prop="align" label="对齐" width="120">
            <template slot-scope="{row, $index}">
              <el-select v-model="form.columns[$index].align" placeholder="请选择" clearable>
                <el-option v-for="o in align_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
              </el-select>
            </template>
          </el-table-column>

          <el-table-column prop="sort" label="排序" width="100">
            <template slot-scope="{row, $index}">
              <el-input-number v-model="form.columns[$index].sort" size="mini" controls-position="right" style="width:100%" />
            </template>
          </el-table-column>
        </el-table>
        
        <el-form-item label="紧凑模式" style="margin-top: 10px;">
          <el-checkbox v-model="form.table_campact" />
        </el-form-item>
        <el-form-item label="默认页大小">
          <el-select v-model="form.page_size" placeholder="请选择">
            <el-option v-for="o in page_size_array" :key="o" :label="o" :value="o"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="固定操作列" style="margin-top: 10px;">
          <el-checkbox v-model="form.fixed_action" />
        </el-form-item>
      </el-form>
    </div>
    
  </el-dialog>
</template>

<script>

import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},

      page_size_array: [20,50,100,500,1000],

      align_options: [
        { label: '左', value: 'left' },
        { label: '居中', value: 'center' },
        { label: '右', value: 'right' },
      ]
    }
  },
  methods: {
    async show(form, options = {}) {
      this.form = _.assign({}, _.cloneDeep(form));
      this.options = _.assign({}, options, {})
      this.visible = true;
    },

    async confirmForm() {
      try {
        this.options.success && this.options.success(_.assign({}, this.form, {
          columns: _.map(this.form.columns, o => _.omitBy(o, (value, key) => _.cond([
            [o => _.includes(['edit'], o.key), _.stubTrue],
            [_.matches({ key: 'rename_label'}), () => !value],
            [_.matches({ key: 'align'}), () => !_.find(this.align_options, { value })],
            [_.stubTrue, _.stubFalse]
          ])({ key })))
        }))
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },

    edit_includes(key) {
      return !!_.find(this.form.columns, o => _.includes(o.edit, key))
    }
  }
}
</script>