<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="800px" top="50px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>
    <div style="max-height: calc(100vh - 250px); overflow-y: auto;">
      <el-form ref="form" :model="form" label-width="100px" >
        <el-table :data="form.fields" style="width: 100%">
          <el-table-column prop="label" label="字段" width="200">
            <template slot-scope="{row, $index}">
              <el-input v-model="form.fields[$index].rename_label" :placeholder="row.label" clearable />
            </template>
          </el-table-column>
          <el-table-column prop="hide" label="隐藏" width="50">
            <template slot-scope="{row, $index}">
              <el-checkbox v-if="row.edit && row.edit.indexOf('hide') !== -1" v-model="form.fields[$index].hide" />
            </template>
          </el-table-column>
          <el-table-column prop="required" label="必填" width="50">
            <template slot-scope="{row, $index}">
              <el-checkbox v-if="row.edit && row.edit.indexOf('required') !== -1" v-model="form.fields[$index].required" />
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    
  </el-dialog>
</template>

<script>

import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
    }
  },
  methods: {
    async show(form, options = {}) {
      this.form = _.assign({}, _.cloneDeep(form));
      this.options = _.assign({}, options, {})
      this.visible = true;
    },

    async confirmForm() {
      try {
        this.options.success && this.options.success(_.assign({}, this.form, {
          fields: _.map(this.form.fields, o => _.omitBy(o, (value, key) => _.cond([
            [o => _.includes(['edit'], o.key), _.stubTrue],
            [_.matches({ key: 'rename_label'}), () => !value],
            [_.stubTrue, _.stubFalse]
          ])({ key })))
        }))

        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>