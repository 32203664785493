<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button :disabled="!form.result" type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">

      <el-form-item label="项目名称">
        {{ form.name }}
      </el-form-item>
      <el-form-item label="责任单位">
        {{ form.dep_name }}
      </el-form-item>
      <el-form-item label="申请资金池">
        【{{ form.zjnf }}】{{ form.cash_pool_name }}
      </el-form-item>
      <el-form-item label="申请金额">
        {{ form.apply_fee }}<span style="margin-left: 10px;">万元</span>
      </el-form-item>

      <el-form-item label="实施主体">
        {{ form.sszt }}
      </el-form-item>

      <el-form-item label="实施时间">
        <span v-if="form.sssj && form.sssj.length">{{ form.sssj[0] }} 至 {{ form.sssj[1] }}</span>
      </el-form-item>

      <el-form-item label="自筹资金">
        <span v-if="form.zczj">
          {{ form.zczj }}
          <span style="margin-left: 10px;">万元</span>
        </span>
      </el-form-item>

      <el-form-item label="类型">
        {{ form.lx }}
      </el-form-item>

      <el-form-item label="审批结果">
        <el-radio-group v-model="form.result">
          <el-radio :label="1">同意</el-radio>
          <el-radio :label="2">驳回</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remarks" placeholder="请输入" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';

export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {  },

      json_keys: ['sssj', 'zczj', 'lx']
    }
  },

  methods: {
    async show(form, options = {}) {
      this.form = _.assign({}, this.defaultForm, form, form.json_data ? _.pick(JSON.parse(form.json_data), this.json_keys) : {});
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        await this.$api.execute('ycjh_cms.project.update_apply_status',
          this.form.id,
          this.form.result === 1 ? this.options.status_1 : this.options.status_2,
          this.form.remarks
        );
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>