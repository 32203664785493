<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="用户名">
        <el-input v-model="form.code" />
      </el-form-item>
      <el-form-item label="密码">
        <el-input v-model="form.password" />
      </el-form-item>

      <el-form-item label="角色">
        <el-select v-model="form.role_id" placeholder="请选择" style="width: 100%">
          <el-option v-for="o in role_options" :key="o.value" :label="o.label" :value="o.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="所在部门">
        <el-cascader v-model="form.dep_id" placeholder="请选择" style="width: 100%" :props="{ label: 'name', value: 'value' }" :options="dep_cascader"></el-cascader>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>

import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      role_options: [],

      dep_cascader: [],
    }
  },

  methods: {
    async show(initForm, options = {}) {
      this.form = _.assign({}, initForm, {});
      this.options = options;

      const [{ list: role_list }, { list: dep_list }] = await this.$api.executeArray(
        ['sys_role.list', {  }],
        ['sys_dep.list', {}]
      )
      this.role_options = _.map(role_list, o => ({ label: o.name, value: o.id }));
      this.dep_cascader = dep_list;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('sys_user.update', this.form.id, this.form);
        } else {
          await this.$api.execute('sys_user.create', this.form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>