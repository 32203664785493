import Vue from 'vue'
import _ from 'lodash';
import * as components from './';

const isVueComponent = obj => !!(obj && (obj.template || obj.render))

const installObject = (obj, parentKey) => _.forEach(obj, (value, key) => {
  const fullKey = parentKey ? [parentKey, key].join('.') : key;
  isVueComponent(value) ? Vue.component(fullKey, value) : installObject(value, fullKey)
})

installObject(components)