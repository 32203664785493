import Vue from 'vue'

import ElementUI from 'element-ui';
import 'normalize.css/normalize.css';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue'
import api from '@/api';
import router from '@/router';
import store from '@/store';
import './index.scss'
import _ from 'lodash'

import './components/install';
import './config_components/install';
import './mixins/install';

Vue.use(ElementUI, { size: 'small'});

Vue.config.productionTip = false
Vue.prototype.$api = api;

if(!localStorage.getItem('debug')) {
  console.log = () => {}
}

new Vue({
  router, store,
  render: h => h(App),
}).$mount('#app')
