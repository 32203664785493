import CryptoJS from 'crypto-js';
import OSS from 'ali-oss';
import moment from 'moment';

function read_chunked(file, chunkCallback, endCallback) {
  const file_size = file.size;
  const chunk_size = 1 * 1024 * 1024;
  let offset = 0;
  
  const reader = new FileReader();
  reader.onload = function() {
    if(reader.error) {
      endCallback(reader.error)
      return;
    }
    offset += reader.result.length;
    chunkCallback(reader.result, offset, file_size);
    if(offset >= file_size) {
      endCallback();
      return;
    }
    read_next();
  }

  reader.onerror = function(error) {
    endCallback(error)
  }

  function read_next() {
    reader.readAsBinaryString(file.slice(offset, offset + chunk_size))
  }

  read_next();
}

function calc_file_md5(file, cbProgress) {
  return new Promise((resolve, reject) => {
    const md5 = CryptoJS.algo.MD5.create();

    read_chunked(file, (chunk, offset, total) => {
      md5.update(CryptoJS.enc.Latin1.parse(chunk))
      cbProgress && cbProgress(offset / total)
    }, error => {
      if(error) {
        reject(error)
      } else {
        resolve(md5.finalize().toString(CryptoJS.enc.Hex))
      }
    })
  })
}


export default {
  methods: {
    async uploadFile(file, options) {

      options.progress && options.progress('请求中')
      const res = await this.$api.execute('ali_oss.sts')
      options.progress && options.progress('计算中')
      const hash = await calc_file_md5(file, e => {
        options.progress && options.progress(`计算中（${(e * 100).toFixed(0)}%）`)
      })
      const name = `upload/erp/${moment().format('YYYYMMDD')}/${hash}/${file.name}`;
      const url = `${res.domain}/${name}`;

      const client = new OSS(_.pick(res, ['region', 'accessKeyId', 'accessKeySecret', 'bucket', 'stsToken']));

      try {
        await client.head(name)
        return url;
      } catch(e) {
        if(e.code === 'NoSuchKey') {
          options.progress && options.progress('上传中')
          await client.multipartUpload(name, file, _.assign({}, options.progress ? {
            progress: p => options.progress(`上传中（${(p * 100).toFixed(0)}%）`)
          } : {}));
          options.progress && options.progress('上传成功')
          return url;
        } else {
          throw e;
        }
      }
    }
  }
}
