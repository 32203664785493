<template>
  <div style="padding: 10px;height: 70px;">
    <div style="margin-bottom: 10px">在建项目未拨付总额</div>
    <count-to :start-val="0" :end-val="value" :duration="3600" style="font-size: 30px;font-weight: bold;" /> 万元
  </div>
</template>

<script>
import BigNumber from 'bignumber.js';
import countTo from 'vue-count-to';

export default {
  label: '统计/项目/在建未拨付总额',
  components: {
    countTo
  },
  data() {
    return {
      value: 0,
      res: null,
    }
  },

  created() {
    this.init();
  }, 
  methods: {
    async init() {
      const res = await this.$api.execute('ycjh_cms.project.stat_count', { status: 3 });
      this.res = res;
      this.value = BigNumber(res.fee).minus(res.paid_fee).toNumber()
    },
  }
}
</script>