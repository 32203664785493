<template>
  <div style="display: inline-block;">
    {{ upload_message || name }}
    <el-upload style="display: inline-block;"
      ref="upload"
      action="/api/upload"
      :show-file-list="false"
      :on-change="uploadChange"
      :on-error="e => $message.error(e.message)"
      :auto-upload="false"
      :limit="1"
      multiple
      >
      <el-button v-if="!upload_message" slot="trigger" type="primary" icon="el-icon-upload" size="mini">{{ value ? `重新上传（${tip}）` : `上传（${tip}）` }}</el-button>
    </el-upload>
    
  </div>
</template>

<script>
import _ from 'lodash';
import { partial } from 'filesize';
const size = partial({base: 2, standard: 'jedec' });

const max_size = 100 * 1024 * 1024;
const max_size_string = size(max_size)

export default {
  props: {
    value: String,
  },

  data() {
    return {
      upload_message: '',
      client: null,
      tip: `最大${max_size_string}`
    }
  },
  computed: {
    name() {
      return this.value ?_.last(this.value.split('/')) : '';
    }
  },

  methods: {
    async uploadChange(e) {
      if(e.size > max_size) {
        this.$error(`上传文件不能大于${max_size_string}`)
        this.$refs.upload.clearFiles();
        return;
      }
      const url = await this.uploadFile(e.raw, {
        progress: message => this.upload_message = message,
      });
      this.$refs.upload.clearFiles();
      this.upload_message = '';

      const size = e.size;

      const file_name = _.last(url.split('/'));
      const dot_idx = file_name.lastIndexOf('.');
      const file_type = dot_idx === -1 ? '' : file_name.substring(dot_idx + 1).toLowerCase();
      const name = dot_idx === -1 ? file_name : file_name.substring(0, dot_idx);

      this.$emit('input', url)
      this.$emit('change', { name, file_name, size, file_type })
    },
  }
}
</script>