<template>
  <div style="padding: 10px; ">
    <echart style="width: 100%; height: 200px;" :option="option" />
  </div>
</template>

<script>
export default {
  label: '统计/资金池/来源占比(图表)',
  data() {
    return {
      option: null,
    }
  },

  created() {
    this.init();
  }, 
  methods: {
    async init() {
      const [stat_by_status, { list: sys_dict_list }] = await this.$api.executeArray(
        ['ycjh_cms.cash_pool.stat_by_zjly', { }],
        ['sys_dict.list', { code: ['zjly'] }]
      );

      const zjly_map = _(_.find(sys_dict_list, { code: 'zjly'})?.children).mapKeys(o => o.code || o.name).mapValues(o => o.name || o.code).value();
      const data = _.map(stat_by_status, o => ({ name: zjly_map[o.zjly] || o.zjly, value: o.fee }))

      this.option = {
        title: {
          text: '资金来源占比',
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} 万元 ({d}%)',
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['50%', '60%'],
            data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      }
    },
  }
}
</script>