<template>
  <div style="position: relative; background: #fff; padding: 15px; display: flex;flex-direction: column;" :style="{ height: fullHeight }">
    <div v-if="accountInfo.user.edit_page" style="position: absolute; right: 10px; top: 5px;">
      <el-button type="text" @click="showForm_config">配置</el-button>
    </div>

    <search-bar v-if="searchColumns.length" :columns="searchColumns" v-model="search" class="search-bar" style="margin-bottom: 10px;" />

    <div class="action-bar" style="margin-bottom: 15px;">
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="createRecord"><i class="icon-add" />新增</el-button>
      <el-button :disabled="loading || selection.length === 0" type="danger" icon="el-icon-delete" size="mini" @click="removeRecord(selection)">删除{{ selection.length ? '选择' : '' }}</el-button>
      <el-button :disabled="loading || total === 0" type="warning" icon="el-icon-download" size="mini" @click="downloadRecord">导出{{ selection.length ? '选择' : '' }}</el-button>
    </div>

    <div style="flex:1">
      <el-table
        v-loading="loading"
        :data="tableData"
        height="100%"
        :default-sort="sort"
        :class="getConfig('table_campact', false) ? 'table-campact' : ''"
        row-key="id"
        :tree-props="{children: 'children'}"
        @selection-change="selectionChange"
        @sort-change="sortChange"
        @cell-dblclick="cellDblclick"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          v-for="o in tableColumns"
          :key="o.prop"
          :type="o.type"
          :prop="o.prop"
          :label="o.rename_label || o.label"
          :width="o.width"
          :min-width="o.minWidth"
          show-overflow-tooltip
          :sortable="o.sortable ? 'custom' : undefined"
          :sort-orders="o.sortable ? ['descending', 'ascending', null] : undefined"
          :align="o.align"
        >
          <template slot-scope="{row}">
            <component :is="o.component" v-if="o.component" :options="o.componentOptions ? o.componentOptions(row) : undefined">{{ renderCell(o, row) }}</component>
            <template v-else>{{ renderCell(o, row) }}</template>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200" :resizable="false" :fixed="getConfig('fixed_action', false) ? 'right' : null">
          <template slot-scope="{row}">
            <el-button type="success" icon="el-icon-edit" size="mini" @click="updateRecord(row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeRecord(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      style="margin-top: 20px; text-align: right;"
      background
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :page-sizes="[20,50,100,500,1000]"
      layout="total, ->, prev, pager, next, sizes"
      :total="total">
    </el-pagination>

    <config-table-form ref="configForm" />

    <edit-form ref="editForm" />
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment'
import * as XLSX from 'xlsx';
import { mapState } from 'vuex';

import EditForm from './Form_edit';

export default {
  label: '系统/用户/全部',
  props: {
    fullHeight: {
      type: String,
    },

    config: {
      type: Object,
      default: () => ({})
    },
  },
  components: {
    EditForm
  },
  data() {
    return {
      columns: [
        { prop: 'id', label: 'ID', width: 100, hide: true, sortable: true },
        { prop: 'index', label: '序号', width: 50, type: '' },
        
        { prop: 'name', label: '姓名', width: 100, edit: ['search'], search: true },
        { prop: 'code', label: '用户名', width: 100, edit: ['search'], search: true },
        { prop: 'role_name', label: '角色', width: 100 },
        { prop: 'dep_name', label: '所在部门', width: 300 },
        { prop: 'create_time', label: '创建时间', width: 160, sortable: true },
      ],

      dict_map: {},

      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      sort: { prop: 'create_time', order: 'descending' },
      selection: [],
      search: { status: 1 },

      downloadState: {
        progressing: false
      },
    }
  },
  computed: {
    ...mapState(['accountInfo']),

    configColumns() {
      return _.map(this.columns, o => _.assign({ }, o, _.pick(_.find(this.config?.columns, { prop: o.prop }), (o.edit || []).concat(['rename_label']))))
    },

    tableColumns() {
      return _(this.configColumns).reject('hide').orderBy(['sort'], ['asc']).value();
    },

    searchColumns() {
      return _(this.configColumns).filter('search').orderBy(['sort'], ['asc']).value();
    },
  },
  watch: {
    currentPage() {
      this.refresh()
    },
    pageSize() {
      this.refresh()
    },
    sort() {
      this.refresh()
    },
    search: {
      handler() {
        this.refresh()
      },
      deep: true,
    }
  },
  created() {
    this.init();
  }, 
  methods: {
    init() {
      this.pageSize = this.config.page_size || this.pageSize;

      this.$api.executeArray(
        ['sys_dict.list', { code: 'xmshzt' }]
      ).then(([{ list: sys_dict_list}]) => {
        this.dict_map = _.mapKeys(sys_dict_list, 'code');  
        this.refresh()
      })
    },
    queryTotalCount({ accountInfo }) {
      // const user_id = accountInfo.user.id;
      return ['sys_user.list', { query_count: true }]
    },

    getConfig(path, def) {
      const value = _.get(this.config, path);
      if(value === undefined) return def;
      return value;
    },
    getQuery(page) {
      return _.assign({
        user_id: this.accountInfo.user.id,
      },
        _.pickBy(this.search, o => o !== '' && o !== null),
        _.pick(this, ['sort']),
        page ? _.pick(this, ['currentPage', 'pageSize']) : {}
      )
    },
    refresh() {
      this.loading = true;
      this.refresh_impl();
    },
    refresh_impl: _.debounce(async function() {
      try {
        this.loading = true;
        const res = await this.$api.execute('sys_user.list', this.getQuery(true));
        this.tableData = _.map(res.list, (o, index) => _.assign({}, o, {
          index: (this.currentPage - 1) * this.pageSize + index + 1,
        }));
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$message.error(e.message);
      }
    }, 300),

    renderCell(column, row) {
      if (column.render) return column.render(row)
      const value = row[column.prop]
      if (column.dict) return _.find(this.dict_map[column.dict]?.children, o => o.code == value || o.name == value)?.name;
      if (column.valueType === 'date') return value ? moment(value).format('YYYY-MM-DD') : ''
      if (column.valueType === 'datetime') return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''
      return value
    },

    sortChange({ prop, order }) {
      this.sort = order ? { prop, order } : null;
    },
    selectionChange(selection) {
      this.selection = selection
    },

    async downloadRecord() {
      const list = this.selection.length ? this.selection : _.map((await this.$api.execute('sys_user.list', this.getQuery())).list, (o, index) => _.assign({}, o, {
        index: (this.currentPage - 1) * this.pageSize + index + 1
      }))
      const wb = XLSX.utils.book_new();
      const columns = this.tableColumns;
      const ws = XLSX.utils.aoa_to_sheet([
        _.map(columns, o => o.rename_label || o.label),
        ..._.map(list, o => _.map(columns, p => this.renderCell(p, o)))
      ])

      var range = XLSX.utils.decode_range(ws['!ref'])
      ws['!cols'] = _.map(_.range(range.e.c - range.s.c + 1), c => {
        const wch = _(_.range(range.e.r - range.s.r + 1)).map(r => {
          var cell_ref = XLSX.utils.encode_cell({ c, r })
          return Math.max((ws[cell_ref] ? _(ws[cell_ref].v).map(str => str.match(/[\u4E00-\u9FFF]/) ? 2 : 1).sum() : 0) + 4, 10)
        }).max()
        return { wch }
      })

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, `导出${moment().format('YYMMDD')}共${list.length}条.xlsx`, {})
    },

    showForm_config() {
      this.$refs.configForm.show(_.assign({}, this.config, {
        columns: this.configColumns
      }), {
        title: '配置',
        success: config => {
          const columns = _(config.columns).map(o => {
            const column = _.find(this.columns, { prop: o.prop });
            const b = _.pickBy(o, (value, key) => column[key] !== value && undefined !== value)
            return _.size(b) ? _.assign({}, b, { prop: o.prop }) : null
          }).compact().value();
          this.$emit('config-changed', _.assign({}, config, { columns }))
        }
      })
    },

    createRecord() {
      this.$refs.editForm.show({ status: 1 }, { title: '创建用户', success: () => {
        this.refresh();
        this.$emit('count-change');
      } });
    },
    updateRecord(obj) {
      this.$refs.editForm.show(obj, { title: '编辑用户', success: () => {
        this.refresh();
      } });
    },

    async removeRecord(obj) {
      try {
        const [arr, msg] = _.isArray(obj)
          ? [[...obj], `此操作将永久删除选择的${obj.length}条记录, 是否继续?`]
          : [[obj], '此操作将永久删除该记录, 是否继续?']

        await this.$confirm(msg, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        await this.$api.executeArray(..._.map(arr, o => ([
          'sys_user.remove', o.id
        ])))

        this.refresh()
        this.$emit('count-change');
        this.$message({ type: 'success', message: '删除成功!' })
      } catch (e) {
        if (!_.includes(['cancel', 'close'], e)) {
          this.$message.error(e.message)
        }
      }
    },
  }
}
</script>