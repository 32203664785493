import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import _ from 'lodash'

import store from '@/store';
import api from '@/api';

const whiteList = ['/login']

Vue.use(VueRouter)

const routes = [
  { path: '/login', component: () => import('./views/Login') },
]

NProgress.configure({ showSpinner: false })

const createRouter = () => new VueRouter({
  mode: 'hash',
  routes
});
const router = createRouter();

router.reset = function() {
  this.matcher = createRouter().matcher;
}

router.beforeEach((to, from, next) => {
  NProgress.start()
  
  if(store.state.accountInfo) {
    console.log('1', to.path)
    if(to.path === '/login') {
      console.log('1.1', to.path)
      next('/');
    } else {
      console.log('1.2', to.path)
      next();
    }
  } else if(to.path === '/login') {
    console.log('2')
    api.execute_throwAllError('account.info').then(res => {
      if(res) {
        console.log('2.1')
        store.commit('setAccountInfo', res);
        next(to.query.redirect || '/');
      } else {
        console.log('2.2')
        next();
      }
    }).catch(e => {
      console.log('2.3')
      next();
    });
    
  } else if(_.includes(whiteList, to.path)) {
    console.log('3')
    next();
  } else {
    console.log('4')
    api.execute_throwAllError('account.info').then(res => {
      if(res) {
        console.log('4.1')
        store.commit('setAccountInfo', res);
        
        console.log('4.1.1')
        next({ ...to, replace: true })
      } else {
        console.log('4.2')
        next(`/login?redirect=${to.path}`)
      }
    }).catch(e => {
      console.log('4.3')
      next(`/login?redirect=${to.path}`)
    })
  }
})

router.afterEach(() => NProgress.done())

export default router;